import { createApp } from 'vue'
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './plugins/axios';
import { abilitiesPlugin } from '@casl/vue';
import { Ability } from '@casl/ability';
import registerBaseComponents from './plugins/base';
import vuetify from './plugins/vuetify';
const app = createApp(App)

import 'material-design-icons-iconfont/dist/material-design-icons.css'
app.use(store)

app.use(vuetify)


// vuetify-confirm replacement (custom script)
import ConfirmPlugin from './plugins/confirm';
app.use(ConfirmPlugin);


import moment from 'moment';
moment.locale('nl');

// app.config.globalProperties => app.config.globalProperties
app.config.globalProperties.moment = moment;

import lodash from 'lodash';

app.config.globalProperties.$lodash = lodash;


import EmailAddresses from './components/commonComponents/BaseEmailAddresses';
app.component('email-addresses', EmailAddresses);

import BaseRow from './components/commonComponents/BaseRow';
app.component('base-row', BaseRow);

import BaseField from './components/commonComponents/BaseField';
app.component('base-field', BaseField);

import BaseNotification from './components/commonComponents/BaseNotification';
app.component('base-notification', BaseNotification);

import BaseTags from './components/commonComponents/BaseTags';
app.component('base-tags', BaseTags);

import BaseCreateUser from './components/commonComponents/BaseCreateUser';
app.component('base-create-user', BaseCreateUser);

import BaseMessage from './components/commonComponents/BaseMessage';
app.component('base-message', BaseMessage);

registerBaseComponents(app);



import vueDebounce from 'vue-debounce';
app.directive('debounce', vueDebounce({ lock: true }))
// app.use(vueDebounce,
//     {
//         lock: false,
//         listenTo: 'keyup',
//         defaultTime: '300ms',
//         fireOnEmpty: false
//     });

import icons from './plugins/icons';
app.config.globalProperties.$icons = icons;

import createEntity from './entity';
app.config.globalProperties.$createEntity = createEntity;

// https://github.com/probil/v-mask/issues/498#issuecomment-827027834
import { VueMaskDirective } from 'v-mask';
const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
};
app.directive('mask', vMaskV3)

app.config.productionTip = false;

app.config.globalProperties.$http = axios;
const accessToken = 'Bearer ' + store.getters.token;

if (accessToken) {
    app.config.globalProperties.$http.defaults.headers.common['Authorization'] = accessToken;
}

app.config.globalProperties.$http.interceptors.response.use(null, error => {
    if (error.response && error.response.status === 401 && store.getters.isLoggedIn) {
        store.dispatch('logout');
    }

    return Promise.reject(error);
})

const ability = new Ability([]);
app.config.globalProperties.ability = ability;
app.use(abilitiesPlugin, ability);
app.provide('ability', ability);
app.config.globalProperties.$axios = axios;

import { Can } from '@casl/vue';

app.use(router)



app.config.globalProperties.$http.interceptors.response.use(null, error => {
    if (error.response && error.response.status === 403 && store.getters.isLoggedIn) {
        router.push('/403');
    }

    return Promise.reject(error);
})

app.config.globalProperties.$http.interceptors.response.use(null, error => {
    if (error.response && error.response.status === 401 && store.getters.isLoggedIn) {
        router.push('/login');
    }

    return Promise.reject(error);
})

import Vue3Toastify, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
app.use(Vue3Toastify, {
    "theme": "colored",
    "position": "bottom-right",
    autoClose: 2000,
    "dangerouslyHTMLString": true
});
app.config.globalProperties.$toast = toast;

import JsonExcel from "vue-json-excel3";
app.component('downloadExcel', JsonExcel);

import Clipboard from 'v-clipboard';

app.use(Clipboard);
app.component('can', Can);



app.mount('#app')
