<template>
  <BaseDraggableModal
      name="inspection_import"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="inspection_import">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="closeModal('inspection_import')">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate('select_inspection') }}</v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
        </template>
        <template #content>
          <v-card-text class="ml-5 mr-5">
            <v-autocomplete
                color="primary"
                bg-color="white"
                variant="underlined"
                :items="inspections"
                v-model="selected_inspection"
                :label="$store.getters.translate('select_inspection')"
                item-title="name"
                item-value="id"
                return-object/>
            <v-autocomplete
                v-if="selected_inspection && zone_types.length > 0"
                color="primary"
                bg-color="white"
                variant="underlined"
                :items="inspection_zones.filter(inspection_zone => inspection_zone.simple_inspection_id === selected_inspection.id)"
                v-model="selected_inspection_zone"
                :label="$store.getters.translate('select_inspection_zone')"
                item-title="name"
                item-value="id"
                return-object/>
            <importer
                v-if="selected_inspection && selected_inspection_zone"
                @result="parseResult"
                :fields="blueprint"
                title="Inspections"/>
          </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";
import Importer from "./Importer";

export default {
  components: {
    Importer,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      loading: false,
      inspections: [],
      inspection_zones: [],
      brands: [],
      ip_types: [],
      equipment_types: [],
      protection_methods: [],
      zone_types: [],
      line_voltage: [],
      inspection_institute_certifications: [],
      inspection_codes: [],
      conformity_marks: [],
      inspection_institutes: [],
      selected_inspection: null,
      selected_inspection_zone: null,
      blueprint: {
        "name": "Inspections",
        "slug": "inspections",
        "allowAdditionalFields": true,
        "mappingConfidenceThreshold": 0.3,
        "fields": [
          {
            "label":"Name",
            "key":"name",
            "alternates":[],
            "constraints": [{ "type": "required" }],
            "type":"string",
          },{
            "label":"Description",
            "key":"description",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Func Location",
            "key":"func_location",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Model",
            "key":"model",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Type",
            "key":"type",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Serial",
            "key":"serial",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"HAZ area classification doc",
            "key":"haz_area_classification_doc",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"HAZ area analysis doc",
            "key":"haz_area_analysis_doc",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Additional notes",
            "key":"additional_notes",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Access",
            "key":"access",
            "alternates":[],
            "validators":[],
            "type":"enum",
            "config": {
              "allow_custom": true,
              "options": [
                {"value": "GROUND", "label": "GROUND"},
                {"value": "STEPS", "label": "STEPS"},
                {"value": "LADDER", "label": "LADDER"},
                {"value": "EXTENSION LADDER", "label": "EXTENSION LADDER"},
                {"value": "SCAFFOLD", "label": "SCAFFOLD"},
                {"value": "HARNESS", "label": "HARNESS"},
              ]
            },
          },{
            "label":"Manufacture year",
            "key":"manufacture_year",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Location latitude",
            "key":"location_latitude",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Location longitude",
            "key":"location_longitude",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Description category",
            "key":"description_category",
            "alternates":[],
            "validators":[],
            "type":"enum",
            "config": {
              "allow_custom": true,
              "options": [
                {"value": "Electrical - IEC 60079 Series", "label": "Electrical - IEC 60079 Series"},
                {"value": "Instrumentation", "label": "Instrumentation"},
                {"value": "Non-electrical - ISO/IEC 80079 Series or EN 13463", "label": "Non-electrical - ISO/IEC 80079 Series or EN 13463"},
                {"value": "Electrical - NEN", "label": "Electrical - NEN"},
                {"value": "Ex Misc Components & Accessories", "label": "Ex Misc Components & Accessories"},
              ]
            },
          },{
            "label":"Equipment group",
            "key":"equipment_group",
            "alternates":[],
            "validators":[],
            "type":"enum",
            "config": {
              "allow_custom": true,
              "options": [
                {"value": "Ex", "label": "Ex"},
                {"value": "I", "label": "I"},
                {"value": "II", "label": "II"},
                {"value": "Sch", "label": "Sch"},
                {"value": "XXXX", "label": "XXXX"},
              ]
            },
          },{
            "label":"Equipment category",
            "key":"equipment_category",
            "alternates":[],
            "validators":[],
            "type":"enum",
            "config": {
              "allow_custom": true,
              "options": [
                {"value": "1", "label": "1"},
                {"value": "1/2", "label": "1/2"},
                {"value": "2", "label": "2"},
                {"value": "2/3", "label": "2/3"},
                {"value": "3", "label": "3"},
              ]
            },
          },{
            "label":"Explosive atmosphere",
            "key":"explosive_atmosphere",
            "alternates":[],
            "validators":[],
            "type":"enum",
            "config": {
              "allow_custom": true,
              "options": [
                {"value": "D", "label": "D"},
                {"value": "G", "label": "G"},
              ]
            },
          },{
            "label":"Regulation",
            "key":"regulation",
            "alternates":[],
            "validators":[],
            "type":"enum",
            "config": {
              "allow_custom": true,
              "options": [
                {"value": "A Ex", "label": "A Ex"},
                {"value": "E Ex", "label": "E Ex"},
                {"value": "Ex", "label": "Ex"},
                {"value": "XXXX", "label": "XXXX"},
              ]
            },
          },{
            "label":"Gas/dust group",
            "key":"gas_dust_group",
            "alternates":[],
            "validators":[],
            "type":"enum",
            "config": {
              "allow_custom": true,
              "options": [
                {"value": "IIA", "label": "IIA"},
                {"value": "IIB", "label": "IIB"},
                {"value": "IIB + H2", "label": "IIB + H2"},
                {"value": "IIC", "label": "IIC"},
                {"value": "IIIA", "label": "IIIA"},
                {"value": "IIIB", "label": "IIIB"},
                {"value": "IIIC", "label": "IIIC"},
              ]
            },
          },{
            "label":"Temperature class",
            "key":"temperature_class",
            "alternates":[],
            "validators":[],
            "type":"enum",
            "config": {
              "allow_custom": true,
              "options": [
                {"value": "T1", "label": "T1"},
                {"value": "T2", "label": "T2"},
                {"value": "T3", "label": "T3"},
                {"value": "T4", "label": "T4"},
                {"value": "T5", "label": "T5"},
                {"value": "T6", "label": "T6"},
              ]
            },
          },{
            "label":"Equipment protection level",
            "key":"equipment_protection_level",
            "alternates":[],
            "validators":[],
            "type":"enum",
            "config": {
              "allow_custom": true,
              "options": [
                {"value": "Ga", "label": "Ga"},
                {"value": "Ga/Gb", "label": "Ga/Gb"},
                {"value": "Gb", "label": "Gb"},
                {"value": "Gc", "label": "Gc"},
                {"value": "Da", "label": "Da"},
                {"value": "Db", "label": "Db"},
                {"value": "Dc", "label": "Dc"},
              ]
            },
          },{
            "label":"Ambient temperature low",
            "key":"ambient_temperature_low",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Ambient temperature high",
            "key":"ambient_temperature_high",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Power",
            "key":"power",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Nominal current",
            "key":"nominal_current",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Markering",
            "key":"markering",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Application",
            "key":"application",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Certificate year",
            "key":"year",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Certificate number",
            "key":"certificate_number",
            "alternates":[],
            "validators":[],
            "type":"string",
          }
        ]
      },
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.inspections = [];
      this.inspection_zones = [];
      this.selected_inspection = null;
      this.selected_inspection_zone = null;
      this.openModal('inspection_import');
      this.loadInspections();
      this.loadInspectionZones();
      this.loadBrands();
      this.loadIpTypes();
      this.loadSimpleProtectionMethods();
      this.loadSimpleEquipmentTypes();
      this.loadZoneTypes();
      this.loadLineVoltage();
      this.loadInspectionInstituteCertifications();
      this.loadInspectionCodes();
      this.loadConformityMarks();
      this.loadInspectionInstitutes();
    },
    async loadInspections() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "SimpleInspection" })
          .then((response) => {
            this.loading = false;
            this.inspections = this.$lodash.sortBy(response.data, (o) => o.name.toLowerCase());
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadInspectionZones() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "InspectionZone" })
          .then((response) => {
            this.loading = false;
            this.inspection_zones = this.$lodash.sortBy(response.data, (o) => o.name.toLowerCase());
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadBrands() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "Brand" })
          .then((response) => {
            this.loading = false;
            this.brands = this.$lodash.sortBy(response.data, (o) => o.name.toLowerCase());

            let options = [];
            this.brands.forEach((option) => {
              options.push({
                value: option.id,
                label: option.name,
              });
            });
            this.blueprint["fields"].push({
              "label":"Brand",
              "key":"brand_id",
              "alternates":[],
              "validators":[],
              "type":"enum",
              "config": {
                "allow_custom": true,
                "options": options
              },
            });
            console.log(this.blueprint);
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadIpTypes() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "IpType" })
          .then((response) => {
            this.loading = false;
            this.ip_types = this.$lodash.sortBy(response.data, (o) => o.name.toLowerCase());

            let options = [];
            this.ip_types.forEach((option) => {
              options.push({
                value: option.id,
                label: option.name,
              });
            });
            this.blueprint["fields"].push({
              "label":"IP type",
              "key":"ip_type_id",
              "alternates":[],
              "validators":[],
              "type":"enum",
              "config": {
                "allow_custom": true,
                "options": options
              },
            });
            console.log(this.blueprint);
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadSimpleProtectionMethods() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "SimpleProtectionMethod" })
          .then((response) => {
            this.loading = false;
            this.protection_methods = this.$lodash.sortBy(response.data, (o) => o.code.toLowerCase());

            let options = [];
            this.protection_methods.forEach((option) => {
              options.push({
                value: option.id,
                label: option.code,
              });
            });
            this.blueprint["fields"].push({
              "label":"Protection method",
              "key":"simple_protection_method_id",
              "alternates":[],
              "validators":[],
              "type":"enum",
              "config": {
                "allow_custom": true,
                "options": options
              },
            });
            console.log(this.blueprint);
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadSimpleEquipmentTypes() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "SimpleEquipmentType" })
          .then((response) => {
            this.loading = false;
            this.equipment_types = this.$lodash.sortBy(response.data, (o) => o.name.toLowerCase());

            let options = [];
            this.equipment_types.forEach((equipment_type) => {
              options.push({
                value: equipment_type.id,
                label: equipment_type.name,
              });
            });
            this.blueprint["fields"].push({
              "label":"Equipment type",
              "key":"simple_equipment_type_id",
              "alternates":[],
              "validators":[],
              "type":"enum",
              "config": {
                "allow_custom": true,
                "options": options
              },
            });
            console.log(this.blueprint);
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadZoneTypes() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "ZoneType" })
          .then((response) => {
            this.loading = false;
            this.zone_types = this.$lodash.sortBy(response.data, (o) => o.name.toLowerCase());
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadLineVoltage() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "LineVoltage" })
          .then((response) => {
            this.loading = false;
            this.line_voltage = this.$lodash.sortBy(response.data, "voltage");

            let options = [];
            this.line_voltage.forEach((option) => {
              options.push({
                value: option.id,
                label: option.voltage + " " + option.ac_dc,
              });
            });
            this.blueprint["fields"].push({
              "label":"Line voltage",
              "key":"line_voltage_id",
              "alternates":[],
              "validators":[],
              "type":"enum",
              "config": {
                "allow_custom": true,
                "options": options
              },
            });
            console.log(this.blueprint);
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadInspectionInstituteCertifications() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "InspectionInstituteCertification" })
          .then((response) => {
            this.loading = false;
            this.inspection_institute_certifications = this.$lodash.sortBy(response.data, (o) => o.name.toLowerCase());

            let options = [];
            this.inspection_institute_certifications.forEach((option) => {
              options.push({
                value: option.id,
                label: option.name,
              });
            });
            this.blueprint["fields"].push({
              "label":"Inspection institute certification",
              "key":"inspection_institute_certification_id",
              "alternates":[],
              "validators":[],
              "type":"enum",
              "config": {
                "allow_custom": true,
                "options": options
              },
            });
            console.log(this.blueprint);
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadInspectionCodes() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "InspectionCode" })
          .then((response) => {
            this.loading = false;
            this.inspection_codes = this.$lodash.sortBy(response.data, (o) => o.code.toLowerCase());

            let options = [];
            this.inspection_codes.forEach((option) => {
              options.push({
                value: option.id,
                label: option.code,
              });
            });
            this.blueprint["fields"].push({
              "label":"Inspection code",
              "key":"inspection_code_id",
              "alternates":[],
              "validators":[],
              "type":"enum",
              "config": {
                "allow_custom": true,
                "options": options
              },
            });
            console.log(this.blueprint);
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadConformityMarks() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "ConformityMark" })
          .then((response) => {
            this.loading = false;
            this.conformity_marks = this.$lodash.sortBy(response.data, (o) => o.name.toLowerCase());

            let options = [];
            this.conformity_marks.forEach((option) => {
              options.push({
                value: option.id,
                label: option.name,
              });
            });
            this.blueprint["fields"].push({
              "label":"Conformity mark",
              "key":"conformity_mark_id",
              "alternates":[],
              "validators":[],
              "type":"enum",
              "config": {
                "allow_custom": true,
                "options": options
              },
            });
            console.log(this.blueprint);
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadInspectionInstitutes() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "InspectionInstitute" })
          .then((response) => {
            this.loading = false;
            this.inspection_institutes = this.$lodash.sortBy(response.data, (o) => o.name.toLowerCase());

            let options = [];
            this.inspection_institutes.forEach((option) => {
              options.push({
                value: option.id,
                label: option.name,
              });
            });
            this.blueprint["fields"].push({
              "label":"Inspection institute",
              "key":"inspection_institute_id",
              "alternates":[],
              "validators":[],
              "type":"enum",
              "config": {
                "allow_custom": true,
                "options": options
              },
            });
            console.log(this.blueprint);
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    parseResult(rows) {
      rows = rows.filter(value => Object.keys(value).length !== 0);
      console.log(rows);
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/import-inspection", { inspection_zone_id: this.selected_inspection_zone.id, rows: rows })
          .then(() => {
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
  },
  watch: {
    selected_inspection: {
      handler() {
        this.selected_inspection_zone = null;
      }
    },
    selected_inspection_zone: {
      handler() {
        if (this.selected_inspection_zone) {
          let zone_types_temp = this.zone_types.filter(option => option.type === this.selected_inspection_zone.type);
          let options = [];
          zone_types_temp.forEach((option) => {
            options.push({
              value: option.id,
              label: option.name,
            });
          });
          this.blueprint["fields"] = this.blueprint["fields"].filter(option => option.key !== "zone_type_id");
          this.blueprint["fields"].push({
            "label": "Zone type",
            "key": "zone_type_id",
            "alternates": [],
            "validators": [],
            "type": "enum",
            "config": {
              "allow_custom": true,
              "options": options
            },
          });

          /*let cert_schemes = [];
          cert_schemes.push({
            "value": this.selected_inspection_zone.cert_scheme,
            "label": this.selected_inspection_zone.cert_scheme
          });
          this.blueprint["fields"] = this.blueprint["fields"].filter(option => option.key !== "cert_scheme");
          this.blueprint["fields"].push({
            "label": "Certification scheme",
            "key": "cert_scheme",
            "alternates": [],
            "validators": [],
            "type": "enum",
            "config": {
              "allow_custom": true,
              "options": cert_schemes
            },
          });*/

          console.log(this.blueprint);
        }
      }
    },
  }
};
</script>