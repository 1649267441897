<template>
  <v-card :loading="loading" :disabled="loading" class="mb-2">
    <v-toolbar v-if="(module_name !== 'profile' && canDelete) || canEdit" flat color="white" dense>
      <v-spacer/>
      <v-btn v-if="module_name !== 'profile' && canDelete" :disabled="loading" size="small" @click="$emit('archive')">
        {{ $store.getters.translate("archive") }}
        <v-icon color="red" size="large" class="ml-2" icon="mdi-archive"/>
      </v-btn>
      <v-btn v-if="canEdit" :disabled="loading" @click="save()" size="small">
        {{ $store.getters.translate("save") }}
        <v-icon color="primary" size="large" class="ml-2" icon="mdi-content-save"/>
      </v-btn>
    </v-toolbar>
    <v-divider/>
    <v-row class="mt-5">
      <v-col lg="3" sm="12" md="12" class="d-flex justify-center">
        <v-row>
          <v-col style="text-align: center">
            <base-profile-photo :isDisabled="!canEdit" :person="record.person" @refresh="save"/>
            <slot name="below_photo"/>
            <v-btn-toggle class="mt-5" v-if="module_name === 'profile'" v-model="record.language">
              <v-btn value="nl">NL</v-btn>
              <v-btn value="en">EN</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-tabs v-if="record.person" v-model="tab" grow>
          <v-tab color="primary">{{ $store.getters.translate("general_info") }}</v-tab>
          <v-tab color="primary">{{ $store.getters.translate("contact_information") }}</v-tab>
          <v-tab color="primary">{{ $store.getters.translate("additional_information") }}</v-tab>
        </v-tabs>
        <v-card-text>
          <v-window v-model="tab">
            <v-window-item>
              <v-card class="ml-5 mr-5" flat elevation="0" rounded="0">
                <v-card-text>
                  <v-text-field
                      color="primary"
                      variant="underlined"
                      :disabled="!canEdit"
                      bg-color="white"
                      v-model="record.person.first_name"
                      required>
                    <template #label>
                      {{$store.getters.translate('first_name')}}
                      <span class="text-red"> <strong>*</strong></span>
                    </template>
                  </v-text-field>
                  <v-text-field
                      color="primary"
                      variant="underlined"
                      :disabled="!canEdit"
                      bg-color="white"
                      :label="$store.getters.translate('insertion')"
                      v-model="record.person.insertion"/>
                  <v-text-field
                      color="primary"
                      variant="underlined"
                      :disabled="!canEdit"
                      bg-color="white"
                      v-model="record.person.last_name"
                      required>
                    <template #label>
                      {{$store.getters.translate('last_name')}}
                      <span class="text-red"><strong>*</strong></span>
                    </template>
                  </v-text-field>
                  <v-text-field
                      color="primary"
                      variant="underlined"
                      :disabled="!canEdit"
                      bg-color="white"
                      :label="$store.getters.translate('initials')"
                      v-model="record.person.initials"/>
                </v-card-text>
              </v-card>
            </v-window-item>
            <v-window-item>
              <v-card class="ml-5 mr-5" flat elevation="0" rounded="0">
                <v-card-text>
                  <template v-for="field in usual_fields">
                    <template v-if="field.name === 'jobtitle'">
                      <base-field
                          v-if="module_name !== 'profile'"
                          :isDisabled="!canEdit"
                          :key="field.name"
                          :modelValue="record[field.name]"
                          :field="field"
                          @change="usualFieldDataChanged"/>
                    </template>
                    <!--EXCLUDE NAME FIELD BECAUSE IT`S ALREADY SHOWN BEFORE AS FIRST AND LAST NAME -->
                    <template v-else-if="field.name !== 'name'">
                      <base-field
                          :isDisabled="!canEdit"
                          :key="field.name"
                          :modelValue="record.person[field.name]"
                          :field="field"
                          @change="usualFieldDataChanged"/>
                    </template>
                  </template>
                  <v-text-field
                      variant="underlined"
                      bg-color="white"
                      :disabled="!canEdit"
                      :label="$store.getters.translate('user_email')"
                      v-if="record.person.user"
                      v-model="record.person.user.email"/>
                  <email-addresses :isDisabled="!canEdit" v-model="record.person.emailaddresses" @update="emailsChanged"/>
                  <base-addresses :isDisabled="!canEdit" v-model="record.person.addresses" @update="addressesChanged"/>
                  <template v-if="tags_required">
                    <base-tags v-if="module_name === 'profile'" :isDisabled="!canEdit" :modelValue="record.person[user_type].tags" :modal_name="user_type + 's'" @change="tagsChanged"/>
                    <base-tags v-else :modelValue="record.tags" :isDisabled="!canEdit" :modal_name="user_type + 's'" @change="tagsChanged"/>
                  </template>
                </v-card-text>
              </v-card>
            </v-window-item>
            <v-window-item>
              <v-card class="ml-5 mr-5" flat elevation="0" rounded="0">
                <v-card-text>
                  <template v-if="record.custom_fields" v-for="field in custom_fields">
                    <base-field
                        v-if="module_name === 'profile'"
                        :isDisabled="!canEdit"
                        :key="field.name"
                        :modelValue="record.person[user_type].custom_fields[field.name]"
                        :field="field"
                        @change="customFieldDataChanged"/>
                    <base-field
                        v-else
                        :isDisabled="!canEdit"
                        :key="field.name"
                        :modelValue="record.custom_fields[field.name]"
                        :field="field"
                        @change="customFieldDataChanged"/>
                  </template>
                  <base-log-field :isDisabled="!canEdit" :model_type="user_type" :model_id="record.id"/>
                </v-card-text>
              </v-card>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import BaseProfilePhoto from "./BaseProfilePhoto";
import BaseAddresses from "../../components/commonComponents/BaseAddresses";
import BaseLogField from "./BaseLogField";
import helpFunctions from "../../plugins/helpFunctions";
import { useAbility } from '@casl/vue';

export default {
  name: "BaseProfile",
  props: ["record", "module_name", "user_type"],
  components: {
    BaseProfilePhoto,
    BaseAddresses,
    BaseLogField,
  },
  setup() {
    const { can } = new useAbility();
    return { can }
  },
  data() {
    return {
      tab: 0,
      show_create_user_dialog: false,
      loading: false,
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
    };
  },
  mounted() {
    this.getFields();
  },
  methods: {
    save() {
      let error = false;
      if (this.record.person.first_name === '' || this.record.person.last_name === '') {
        this.$toast.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate('name'));
        error = true;
      }
      this.usual_fields.forEach((field) => {
        if (field.mandatory && field.field_type !== 'boolean' && field.name !== 'jobtitle' && field.name !== 'name') {
          if(!this.record.person[field.name] || this.record.person[field.name] === '') {
            this.$toast.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
            error = true;
          }
        }
      });
      if(!error) {
        this.$emit('update:record', this.record);
      }
    },
    usualFieldDataChanged(data) {
      if(data[0] === 'jobtitle') {
        this.record[data[0]] = data[1];
      }
      else {
        this.record.person[data[0]] = data[1];
      }
    },
    emailsChanged(updated_emails) {
      this.record.person.emailaddresses = updated_emails;
      this.save();
    },
    addressesChanged(updated_addresses) {
      this.record.person.addresses = updated_addresses;
    },
    tagsChanged(data) {
      this.record.tags = data;
    },
    customFieldDataChanged(data) {
      if(this.module_name === 'profile') {
        this.record.person[this.user_type].custom_fields[data[0]] = data[1];
      }
      else {
        this.record.custom_fields[data[0]] = data[1];
      }
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.user_type, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
  },
  computed: {
    canEdit() {
      if (this.module_name === 'profile') {
        return true;
      }
      else {
        return this.can("edit", this.$createEntity(this.user_type, {id: 0})) ||
            this.can("edit", this.$createEntity(this.user_type, {id: this.record.id}));
      }
    },
    canDelete() {
      return this.can("delete", this.$createEntity(this.user_type, { id: 0 })) ||
          this.can("delete", this.$createEntity(this.user_type, { id: this.record.id }));
    },
  },
};
</script>