<template>
  <BaseDraggableModal
      name="base_export"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="base_export">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="closeModal('base_export')">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate('export_to_excel') }}</v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
        </template>
        <template #content>
          <v-card-text class="ma-5">
            <v-row>
              <v-col>{{ $store.getters.translate("export_filtered_data") }}</v-col>
              <v-col>
                <download-excel style="font-size: 12px; text-transform: uppercase; cursor: pointer;" :fields="show_in_search_fields" :data="data" :name="page.name + '.xls'">
                  {{ $store.getters.translate("export_to_excel") }}
                  <v-icon color="primary" size="large" class="ml-2 mr-2" icon="mdi-file-excel"/>
                </download-excel>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col>{{ $store.getters.translate("export_all_data") }}</v-col>
              <v-col>
                <download-excel style="font-size: 12px; text-transform: uppercase; cursor: pointer;" :fields="all_fields" :fetch="getRecordsForExport" :name="page.name + '.xls'">
                  {{ $store.getters.translate("export_to_excel") }}
                  <v-icon color="primary" size="large" class="ml-2 mr-2" icon="mdi-file-excel"/>
                </download-excel>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  props: ["page", "data"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      loading: false,
      show_in_search_fields: {},
      all_fields: {}
    };
  },
  created() {
    this.getShowInSearchFields();
    this.getAllFields();
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.openModal('base_export');
    },
    async getRecordsForExport() {
      this.loading = true;
      return this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=export")
          .then((response) => {
            this.loading = false;
            response.data.data.forEach((item) => {
              if(item.distribution_device_function_name) {
                item.distribution_device_function_name = this.$store.getters.translate(item.distribution_device_function_name);
              }
            });
            return response.data.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    async getShowInSearchFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?show_in_search=true&model_type=" + helpFunctions.models[this.page.name].toLowerCase())
          .then((response) => {
            this.show_in_search_fields = [];
            response.data.forEach((item) => {
              item.name = helpFunctions.replaceIDwithNameBaseTableInc(item.name);
              this.show_in_search_fields[this.$store.getters.translate(item.name)] = item.name;
            });
          })
          .catch((error) => {
            console.log(error);
          });
    },
    async getAllFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?model_type=" + helpFunctions.models[this.page.name].toLowerCase())
          .then((response) => {
            this.all_fields = [];
            response.data.forEach((item) => {
              item.name = helpFunctions.replaceIDwithNameBaseTableInc(item.name);
              this.all_fields[this.$store.getters.translate(item.name)] = item.name;
            });
          })
          .catch((error) => {
            console.log(error);
          });
    },
  }
};
</script>