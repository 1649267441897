<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="closeModal(modal_name)">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title v-if="label">{{ $store.getters.translate(label) }}</v-toolbar-title>
            <v-toolbar-title v-else>{{ page.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn v-if="canDelete" :disabled="loading" size="small" @click="archive()">
              <v-icon icon="mdi-trash-can" size="x-large"/>
            </v-btn>
            <v-btn v-if="canEdit" :disabled="loading" size="small" @click="save(true)">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
            <v-card-text class="ml-5 mr-5">
              <!--USUAL FIELDS-->
              <base-field
                  v-for="field in usual_fields"
                  :key="field.name"
                  :isDisabled="!canEdit"
                  :modelValue="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
              <!--CUSTOM FIELDS-->
              <base-field
                  v-if="record.custom_fields"
                  v-for="field in custom_fields"
                  :key="field.name"
                  :isDisabled="!canEdit"
                  :modelValue="record.custom_fields[field.name]"
                  :field="field"
                  @change="customFieldDataChanged"/>
              <!--LIST FILES-->
              <template v-if="record.files" v-for="(file, index) in record.files" :key="index">
                <base-row
                    class="mt-3"
                    :label="$store.getters.translate('file')"
                    v-model="file.name"
                    @click="downloadFile(file)"
                    :link="true"
                    :hide_copy="true"
                    :deletable="canEdit"
                    @delete="deleteFile(file)"/>
              </template>
              <!--UPLOAD FILES-->
              <v-row v-if="files_required">
                <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                  <v-file-input
                      v-model="new_files"
                      :disabled="!canEdit"
                      :multiple="multiple_files"
                      @change="saveFiles"
                      :label="$store.getters.translate('files')"
                      placeholder="Drop file to upload"
                      class="mt-3"
                      color="primary"
                      bg-color="white"
                      show-size/>
                </v-col>
              </v-row>
              <!--TAGS-->
              <base-tags v-if="tags_required" :isDisabled="!canEdit" :modelValue="record.tags" :modal_name="page.name" @change="tagsChanged"/>
            </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import { useAbility } from '@casl/vue'

export default {
  props: ["modal_name", "page", "files_required", "multiple_files"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  setup() {
    const { can } = new useAbility();
    return {
      can
    }
  },
  data() {
    return {
      label: null,
      record: {},
      loading: false,
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      //FILES VARIABLE
      new_files: []
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load(id) {
      this.record = {};
      this.new_files = [];
      this.usual_fields = [];
      this.custom_fields = [];
      this.openModal(this.modal_name);
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toast, this.page.name, id);
      if(this.page.model === 'tag') {
        this.record.name = this.record.name.en;
      }
      await this.getFields();
    },
    async save(reload) {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toast, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toast, this.page.name, this.record)) {
            if(this.page.model === 'translation') {
              await this.$store.dispatch("refreshTranslations");
            }
            if (reload) {
              this.$emit("refresh");
              this.closeModal(this.modal_name);
            }
          }
          this.loading = false;
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toast, this.page.name, this.record.id)) {
            this.$emit("refresh");
            this.closeModal(this.modal_name);
          }
          this.loading = false;
        }
      });
    },
    usualFieldDataChanged(data) {
      if(this.record[data[0]] !== data[1]) {
        this.record[data[0]] = data[1];
      }
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    tagsChanged(data) {
      this.record.tags = data;
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
    downloadFile(file) {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/files/" + file.id, { responseType: "blob" })
          .then((response) => {
            this.loading = false;
            const blob = new Blob([response.data], { type: file.content_type });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.target = "_blank";
            link.click();
            URL.revokeObjectURL(link.href);
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    deleteFile(file) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
              .delete(this.$store.getters.appUrl + "v2/files/" + file.id)
              .then(() => {
                this.loading = false;
                this.$toast.success(this.$store.getters.translate("successfully_deleted"));
                this.load(this.record.id);
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toast.error(error.message);
                }
                this.loading = false;
              });
        }
      });
    },
    addDropFile(e) {
      this.uploadFiles(e.dataTransfer.files);
    },
    saveFiles() {
      if (this.new_files) {
        this.uploadFiles(this.new_files);
      }
    },
    async uploadFiles(files) {
      await this.save(false);
      this.loading = true;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("attachment[]", files[i]);
      }
      formData.append("id", this.record.id);
      this.$http
          .post(this.$store.getters.appUrl + "v2/" + this.page.name + "/uploadfiles", formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            this.loading = false;
            this.$toast.success(this.$store.getters.translate("successfully_saved"));
            this.load(this.record.id);
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
  },
  computed: {
    canEdit() {
      return this.can("edit", this.$createEntity(this.page.model.toLowerCase(), { id: 0 })) ||
          this.can("edit", this.$createEntity(this.page.model.toLowerCase(), { id: this.record.id })) ||
          //IF THE USER HAS CUSTOM PERMISSION TO EDIT PARENT INSPECTION WE ALLOW HIM TO DO THE SAME WITH IT`S ITEMS
          (this.page.model === "generaldefect" && this.record.simple_inspection_id && this.can("edit", this.$createEntity("simpleinspection", { id: this.record.simple_inspection_id })));
    },
    canDelete() {
      return this.can("delete", this.$createEntity(this.page.model.toLowerCase(), { id: 0 })) ||
          this.can("delete", this.$createEntity(this.page.model.toLowerCase(), { id: this.record.id })) ||
          //IF THE USER HAS CUSTOM PERMISSION TO EDIT PARENT INSPECTION WE ALLOW HIM TO DO THE SAME WITH IT`S ITEMS
          (this.page.model === "generaldefect" && this.record.simple_inspection_id && this.can("delete", this.$createEntity("simpleinspection", { id: this.record.simple_inspection_id })));
    },
  },
};
</script>